<!--
 * @Author: your name
 * @Date: 2021-11-30 10:27:06
 * @LastEditTime: 2022-01-04 17:47:22
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \sproutedus\src\views\product\page1.vue
-->
<template>
  <div id="page2">
    <!--  -->
    <div class="contain contain2" data-aos="fade-up" data-aos-once="true">
      <div class="bigTitle">
        <div class="bigTitle-contain">
          <h3>三大智能音箱接入</h3>
          <p>以智能终端为载体，搭载小新课堂应用，秒变家庭教育神器；益智儿歌，经典童话，百科常识，国学、英语、科学启蒙，既是孩子的玩伴，又是智慧小老师。</p>
          <ul>
            <li>孩子在家学 爸妈更省心</li>
            <li>一键安装 开机即学</li>
          </ul>
          <div class="appBg animated bounceIn">
            <img src="../../assets/show5.png" alt />
          </div>
          <div class="imgTip">
            <img src="../../assets/221.gif" alt v-if="loopOpen" />
          </div>
        </div>
      </div>
      <div class="appIntrodoce">
        <div class="appIntrodoce-left">
          <img src="../../assets/iconApp.png" alt="app" />
        </div>
        <div class="appIntrodoce-right">
          <h3>小新同步课堂</h3>
          <p>一款专为小学生打造的学习辅导应用，小新同步课堂汇聚大量优质数字知识内容，帮助孩子更好地掌握知识。</p>
        </div>
      </div>

      <div class="appOffice" data-aos="fade-up" data-aos-once="true">
        <div class="officeContain">
          <h3>满足小学生的所有知识需求</h3>
          <p>
            涵盖小学
            <b>多版本、全学科</b>的学习及拓展知识内容，还有更多实用工具免费使用。
          </p>
          <div class="officeContain-img">
            <img src="../../assets/show6.png" alt />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name:'page2',
    data(){
        return{
            loopOpen:false,
        }
    },
    mounted(){
        this.getPlay()
    },
    destroyed(){
        clearInterval(this.time)
    },
    methods:{
        getPlay(){
            this.loopOpen = true;
            this.time = setTimeout(()=>{
                this.loopOpen = false;
                this.getPlay()
            },3000)
        },
    }
}
</script>
<style lang="scss">
.contain2 {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.bigTitle {
  width: 100%;
  height: 500px;
  background: #4967ff;
  &-contain {
    width: 1200px;
    margin: 0 auto;
    z-index: 2;
    position: relative;
    h3 {
      font-size: 56px;
      font-weight: bold;
      line-height: 95px;
      color: #ffffff;
      padding: 96px 0 16px 30px;
    }
    p {
      width: 564px;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 28px;
      color: #ffffff;
      opacity: 0.8;
      padding-left: 30px;
      box-sizing: border-box;
    }
    ul {
      margin-top: 39px;
      padding-left: 51px;
      li {
        font-size: 24px;
        font-weight: 500;
        line-height: 41px;
        color: #ffffff;
      }
    }
    .appBg {
      position: absolute;
      top: 110px;
      right: -139px;
      width: 900px;
      height: 643px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.appIntrodoce {
  width: 1200px;
  margin: 0 auto;
  height: 340px;
  &-left {
    display: inline-block;
    width: 142px;
    height: 142px;
    margin: 39px 0 0 4px;
    vertical-align: top;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-right {
    display: inline-block;
    width: 368px;
    margin: 66px 0 0 8px;
    vertical-align: top;
    h3 {
      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #000000;
      line-height: 28px;
    }
    p {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      line-height: 27px;
      color: #5e6577;
      margin-top: 11px;
    }
  }
  &-item {
    display: inline-block;
    width: 571px;
    height: 106px;
    z-index: 3;
    margin: 34px 0 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.appOffice {
  width: 100%;
  background-color: #f4f7f9;
  padding-bottom: 98px;
  .officeContain {
    text-align: center;
    padding-top: 100px;
    h3 {
      display: inline-block;
      font-size: 38px;
      font-weight: bold;
      color: #000000;
      position: relative;
    }
    h3::before {
      position: absolute;
      top: 50%;
      left: -90px;
      display: block;
      content: "";
      background: url("../../assets/titleLeft.png") no-repeat;
      width: 74px;
      height: 3px;
      transform: translateY(50%);
    }
    h3::after {
      position: absolute;
      top: 50%;
      right: -90px;
      display: block;
      content: "";
      background: url("../../assets/titleRight.png") no-repeat;
      width: 74px;
      height: 3px;
      transform: translateY(50%);
    }
    p {
      // width: 732px;
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #5e6577;
      text-align: center;
      margin: 68px auto 0;
      b {
        font-size: 40px;
        color: #41cf14;
      }
    }
  }
  .officeContain-img {
    width: 1130px;
    height: 510px;
    margin: 100px auto 0;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.contain {
  animation-duration: 500ms;
}
.imgTip {
  position: absolute;
  top: 52px;
  right: -100px;
  width: 600px;
  height: 48px;
  background: transparent;
  border-radius: 24px;
  font-size: 20px;
  font-weight: bold;
  line-height: 48px;
  text-align: center;
  color: #ffffff;
  // opacity: 0.74;
  img {
    width: 100%;
    height: 100%;
  }
}
</style>